<template>
  <section>

    <FramePoem
      v-if="item.photo != ''"
      :photo="'/img/' + item.photo"
      :text="item.text"
    ></FramePoem>

    <div
      v-if="item.text === 'error'"
    >
      <p class="error">Nie ma takiej tematyki wierszy!</p>
      <p class="error">Wybierz tematykę z powyższej listy.</p>
    </div>
    <div
      class="titles-container"
      v-else
    >
      <router-link
        v-for="(poem, index) in poems" :key="index"
        @click="setPoem(poem, index)"
        :to="{ name: 'PoemPage', params: { poemLink: poem.poemLink } }"
      >
        <p class="title">{{ poem.title }}</p>
      </router-link>
    </div>

    <div
        v-if="$route.params.poemLink"
        class="poem-bg"
        @click="closePoem()"
    ></div>

  <transition name="fade">
    <div
      class="poem-container"
      ref="poemContainer"
      v-if="$route.params.poemLink"
    >
      <router-view :key="$route.params.poemLink"></router-view>
    </div>
    </transition>
    
  </section>
</template>

<script>
import FramePoem from '@/components/FramePoem'

export default {
  name: 'PoemsBox',
  components: {FramePoem},
  data: () => ({
    
  }),
  computed: {
    theme () {
      return this.$route.params.theme
    },
    poemLink () {
      return this.$route.params.poemLink ? this.$route.params.poemLink : null
    },
    newestOptions () {
      return this.$store.state.data.poems.newest
    },
    item () {
      let themes = this.$store.state.data.poems.themes
      if (themes.length == 1 && themes[0].name === '') {
        return {
          photo: '',
          text: ''
        }
      }
      else if (this.theme === this.$store.state.data.poems.newest.link) {
        return this.$store.state.data.poems.newest
      }
      else {
        let _item = themes.find(el => el.link === this.theme)
        if (_item == undefined)  {
          return {
            photo: '',
            text: 'error'
          }
        }
        else {
          return _item
        }
      }
    },
    poems () {
      let _poems = this.$store.state.poems.filter(el => {
        return this.theme === this.newestOptions.link ? el.newest === true : el.link === this.theme
      })
      _poems.forEach(el => {
        el.poemLink = encodeURIComponent(el.title.toLowerCase()
          .replace(/ą/g, 'a')
          .replace(/ć/g, 'c')
          .replace(/ę/g, 'e')
          .replace(/ł/g, 'l')
          .replace(/ń/g, 'n')
          .replace(/ó/g, 'o')
          .replace(/ś/g, 's')
          .replace(/ż/g, 'z')
          .replace(/ź/g, 'z')
          .replace(/\s/g, '-')
          .replace(/,/g, '')
          .replace(/"/g, ''))
      })
      return _poems
    }
  },
  methods: {
    setOverflow () {
      if (this.$route.params.poemLink) {
        document.body.style.overflowY = 'hidden'
      }
      else {
        document.body.style.overflowY = 'visible'
      }
    },
    closePoem () {
      this.$router.push({ name: 'PoemsBox', params: { theme: this.theme } })
    }
  },
  created() {
    this.setOverflow()
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$refs.poemContainer) {
      this.$refs.poemContainer.scroll({top: 0, behavior: 'smooth'})
    }
    setTimeout(() => {
      next()
    }, 500)
  },
  watch: {
    poemLink: function () {
      this.setOverflow()
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.titles-container {
  padding: 0 30px;
  @media screen and (min-width: 960px) {
    transform: translateY(-40px);
  }
}
.title {
  font-weight: 300;
  margin: 10px 15px;
  padding: 0 10px;
  transition: all .15s linear;
  border-left: 0px solid #EB7054;
  @media screen and (min-width: 600px) {
    width: 300px;
    &:hover {
      color: #EB7054;
      cursor: pointer;
      border-left: 10px solid #EB7054;
    }
  }
}
.poem-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  max-height: 900px;
  width: 90vw;
  height: 90vh;
  background-color: white;
  box-shadow: 0px 5px 15px 5px #444;
  z-index: 300;
  overflow-y: scroll;
  overflow-x: hidden;
}
.poem-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  transition: all .25s ease-in-out;
  flex-grow: 1;
}
.poem-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
}
.top-poem-nav {
    position: sticky;
    top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 500;
}
.icon-magnify {
  width: 40px;
  height: 50px;
  color: #444;
  transition: all .25s linear;
  @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054;
      }
    }
}
.close-poem-btn {
    width: 40px;
    height: 70px;
    font-size: 3rem;
    color: #444;
    transition: all .25s linear;
    margin-left: 15px;
    @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054;
      }
    }
}
.title-display {
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0 15px 20px 15px;
  text-align: center;
  transition: all .15s linear;
}
.text-display-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-display {
  margin: 0 15px 30px 15px;
  display: inline-block;
  line-height: 2.5rem;
  transition: all .15s linear;
}
.poems-nav {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 15px;
  div {
    text-align: center;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 600px) {
    flex-direction: row;
    div {
      flex-basis: 50%;
      padding: 10px 15px;
      transition: all .25s linear;
      height: 100%;
      max-height: 80px;
      &:hover {
        background-color: #EB7054;
        color: white;
        cursor: pointer;
      }
    }
    .btn-left {
      text-align: left;
      margin-left: 25px;
      margin: 15px auto 5px 0;
      padding-left: 30px;
    }
    .btn-right {
      text-align: right;
      margin-right: 25px;
      margin: 15px 0 5px auto;
      padding-right: 30px;
    }
  }
}
.nav-btn {
  font-style: italic;
  font-size: 1rem;
}
.error {
  color: red;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 15px;
}

</style>
